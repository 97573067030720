<template>
	<div class="single-view tool-timestamp">
		<div class="tool-box">
			<h1>{{ ii('TIMESTAMP_CONVERTER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						v-model="app.query.time"
						ref="tf"
						prepend-icon="clock-o"
						:placeholder="ii('INPUT_DATE_OR_TIMESTAMP')"
						@change="refreshQuery"
					></s-text-field>
				</div>
				<div>
					<table class="result-tbl">
						<tr v-if="timestamp">
							<td>{{ ii('TIMESTAMP') }}:</td>
							<td>{{ timestamp }}</td>
						</tr>
						<tr v-if="datetimeFull">
							<td>{{ ii('FULL_TIME') }}:</td>
							<td>{{ datetimeFull }}</td>
						</tr>
						<tr v-if="datetime">
							<td>{{ ii('DATETIME') }}:</td>
							<td>{{ datetime }}</td>
						</tr>
						<tr v-if="fromNow">
							<td>{{ ii('WHEN') }}:</td>
							<td>{{ fromNow }}</td>
						</tr>
					</table>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

				<div class="examples-box">
					<h3>{{ ii('INPUT_EXAMPLES') }}:</h3>
					<ul>
						<li v-for="(example, i) in examples">
							<span class="link" @click="onExampleClick(example)">{{ example }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="less">
@import "../styles/vars";

.tool-timestamp {
	.timestamp-tf-outer {
		margin: 0 auto;
		max-width: 500px;
	}
}
</style>

<script>
import chrono from 'chrono-node';
import moment from 'moment';

export default {
	data() {
		return {
			parsed: null,
			examples: [
				'now',
				'1520010030',
				'Last monday',
				'2 weeks ago',
				'2018-04-07 10:12:30',
				'+3 days'
			]
		};
	},
	computed: {
		timestamp() {
			if (!this.parsed) return '';

			return Math.round(this.parsed.getTime() / 1000);
		},
        datetimeFull() {
			if (!this.parsed) return '';

			return this.parsed.toUTCString();
		},
        datetime() {
            if (!this.parsed) return '';

            return moment(this.parsed).format('YYYY-MM-DD HH:mm:ss');
		},
		fromNow() {
			if (!this.parsed) return '';

			//force rerender
			this.app.lang;

			let ts = Math.floor(this.parsed.getTime() / 1000);
			let now = Math.floor(Date.now() / 1000);
			if (ts === now) return 'now';

			return moment(this.parsed).fromNow();
		}
	},
	methods: {
		onExampleClick(example) {
			this.editQueryParams({time: example});

			this.$refs.tf.focus();
		},
		update() {
			if (!this.app.query.time) {
				this.parsed = null;
				return;
			}

			let raw = this.app.query.time.trim();
			if (!raw) {
				this.parsed = null;
			} else if (isNaN(raw)) {
				this.parsed = chrono.parseDate(raw);
			} else {
				let ts = parseInt(raw);
				if (isNaN(ts)) {
					this.parsed = null;
					return;
				}
				if (ts <= 2147483647) {
					ts *= 1000;
				}
				this.parsed = new Date(ts);
			}
		}
	},
	watch: {
		'app.query.time'() {
			this.update();
		}
	},
	async mounted() {
		this.update();

		if (!this.app.isMob) {
			this.$refs.tf.focus();
		}
	}
}
</script>
